import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Title from "../components/title/title"
import TxtImg from "../components/txtImg/txtImg"
import FullwidthBox from "../components/fullwidthBox/fullwidthBox"
import ThreeCol from "../components/threeCol/threeCol"
import Subscribe from "../components/subscribe/subscribe"
import LondonModular from "../images/London-Modular.jpg"
import { Helmet } from "react-helmet"

export default function Research({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Research | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <Title
        title="Independent research"
        subtitle="Studies into the technical performance of Caplin Solar’s patented Earth Energy Bank and thermal storage system have been ongoing since 2013."
        gradient={true}
      />
      <section style={{backgroundColor: "var(--neutral-100)", paddingBottom: "0rem"}}>
        <TxtImg
          heading="The Solar House"
          subheading="Demonstrator project"
          linkTo="/research#Press"
          linkName="In the press"
          imgSrc={data.solarHouse.childImageSharp.fluid}
          imgAlt="The Solar House"
        >
          <p>Caplin Homes built and installed the first Caplin Solar thermal storage system in a demonstrator house, the Solar House, in 2013. The project attracted a lot of international press attention as the UK’s first home to be heated solely by the sun’s energy all year round. The system’s technical performance was monitored and analysed year round by experts from De Montfort University’s IESD, a world leading energy research institute.</p>
        </TxtImg>
      </section>
      <section style={{backgroundColor: "var(--neutral-200)", padding: "4rem 0"}}>
        <TxtImg
          reverse={true}
          heading="DMU's further study"
          underline={true}
          imgSrc={data.campusImage.childImageSharp.fluid}
          imgAlt="Terraced house study location"
        >
          <p>DMU’s Institute of Energy and Sustainable Development (IESD) went on to install the system, for further study, in a traditional terraced house just yards from the university’s city centre campus.</p>
          <p>Dr. Rick Greenough explained: “Having a fully instrumented working model will enable us to undertake detailed research into the performance of the EEB and our students to see first-hand the latest renewable innovations and explore further applications."</p>
        </TxtImg>
      </section>
      <FullwidthBox image={LondonModular}>
        <span style={{color: "var(--accent-orange-600)"}}>Cardiff University</span> will also be engaging in a year-long study of the Caplin Solar technology at a 47 property development currently under construction in Corby, Northamptonshire.
      </FullwidthBox>
      <ThreeCol type="Press" anchorId="Press"/>
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query {
    solarHouse: file(relativePath: {eq: "images/solar-house.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    campusImage: file(relativePath: {eq: "images/DMU-campus.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`